<template>
	<v-container>
		<v-row
			align="center"
			justify="center">
			<h1>{{ course.name }}</h1>
		</v-row>

		<v-row>
			<v-col
				cols="12"
				sm="6"
				md="4"
				v-for="(courseSection) in courseSections"
				:key="`section-${courseSection.id}`">
				<CourseSectionCard
					:course-section="courseSection">
				</CourseSectionCard>
			</v-col>

			<v-col
				cols="12"
				sm="6"
				md="4"
				v-for="lesson in lessons"
				:key="`lesson-${lesson.id}`">
				<LessonCard
					:lesson="lesson">
				</LessonCard>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { apiFactory } from '../api/apiFactory'

import Course from '../models/Course'
import CourseSection from '../models/CourseSection'

import CourseSectionCard from '../components/Courses/CourseSectionCard.vue'
import LessonCard from '../components/Courses/LessonCard.vue'

const lessonsApi = apiFactory.get('lessons')

export default {
	components: {
		CourseSectionCard,
		LessonCard,
	},
	props: {
		courseid: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		lessons: [],
	}),
	computed: {
		course() {
			return Course.find(this.courseid) || {}
		},
		courseSections() {
			return CourseSection.query()
				.where('course_id', this.courseid)
				.get()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadCourse(courseid) {
			try {
				await Course.fetchGet(courseid)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити курс',
					color: 'error',
				}, { root: true })
				throw error
			}
		},

		async loadCourseSections(courseid) {
			try {
				await CourseSection.fetchGetAllByCourse(courseid)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити курс',
					color: 'error',
				}, { root: true })
				throw error
			}
		},

		async loadLessons(courseid) {
			try {
				const { data } = await lessonsApi.getByCourse(courseid)
				this.lessons = data
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список занять',
					color: 'error',
				}, { root: true })
			}
		},
	},
	mounted() {
		this.loadCourse(this.courseid)
		this.loadLessons(this.courseid)
	},
}
</script>
