<template>
	<v-card
		:to="{
			name: 'lesson',
			params: {
				lessonid: lesson.id,
			},
		}"
		style="height: 100%;"
		elevation="24">
		<v-card-subtitle
			v-if="lesson.course_section"
			class="pb-0">
			<v-chip dark small>
				{{ lesson.course_section.name }}
			</v-chip>
		</v-card-subtitle>
		<v-card-title>
			{{ lesson.order }}. {{ lesson.name }}
		</v-card-title>
		<v-card-text>
			<small>Додано {{ lesson.created_at | formatDistanceToNow }}</small>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		lesson: {
			type: Object,
			required: true,
		},
	},
}
</script>