<template>
	<v-card
		:to="{
			name: 'course-section',
			params: {
				courseSectionId: courseSection.id,
			},
		}"
		style="height: 100%;"
		elevation="24"
		outlined
		dark>
		<v-card-title>
			<v-icon class="mr-4">mdi-folder</v-icon>
			{{ courseSection.name }}
		</v-card-title>
	</v-card>
</template>

<script>
export default {
	props: {
		courseSection: {
			type: Object,
			required: true,
		},
	},
}
</script>